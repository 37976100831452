/*
 * Form Validate
 * Ivan Kolesnikov (myivanko@gmail.com)
 */



// FORM

function validateForm(form, properties) {

    var isFormValid = true,
        isFieldValid = true;

    for (var i = 0; i < properties.length; i++) {

        var field = document.getElementById(properties[i].id),
            val = field.value;

        if (field.disabled) return;

        if (!field.validateListener) {
            field.addEventListener('blur', function() {
                validateForm(form, properties);
            });
            field.validateListener = true;
        }

        if (typeof properties[i].check !== 'undefined') {

            switch (properties[i].check) {
                case 'email':
                    isFieldValid = validateEmail(val);
                    break;
                case 'select':
                    isFieldValid = validateSelect(val);
                    break;
                case 'name':
                    isFieldValid = validateName(val);
                    break;
                case 'number':
                    isFieldValid = validateNumber(val);
                    break;
                case 'zip':
                    isFieldValid = validateZip(val);
                    break;
                case 'price':
                    isFieldValid = validatePrice(val);
                    break;
                case 'check':
                    isFieldValid = validateCheck(field);
                    break;
                default:
                    isFieldValid = validateString(val, properties[i].check);
            }

        } else {
            isFieldValid = !validateString(val, '');
        }

        var msg = field.nextElementSibling;
        if (msg && !msg.classList.contains('c-form__feedback')) msg = false;

        if (isFieldValid) {

            field.classList.remove('is-invalid');
            if (msg) msg.parentNode.removeChild(msg);

        } else {

            isFormValid = false;
            field.classList.add('is-invalid');
            if (!msg) {
                msg = document.createElement('span');
                msg.className = 'c-form__feedback';
                msg.innerHTML = properties[i].msg;
                field.parentNode.insertBefore(msg, field.nextSibling);
            }

        }

    }

    form.valid = isFormValid;

    return isFormValid;

}



function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
function validateName(string) {
    var re = /^[A-Za-z\s]+$/;
    return re.test(string);
}
function validateSelect(val) {
    var valid = true;
    if (val == 'placeholder') valid = false;
    return valid;
}
function validateZip(zip) {
    var re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return re.test(zip);
}
function validateString(val, string) {
    var equally = false;
    if (val == string) equally = true;
    return equally;
}
function validateNumber(number) {
    return !isNaN(parseFloat(number)) && isFinite(number);
}
function validatePrice(price) {
    var re = /^\d*(?:\.\d{0,2})?$/;
    price = price.replace(/\s/g, '');
    return re.test(price) && price != '';
}
function validateCheck(check) {
    return check.checked;
}