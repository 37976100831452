// Initialize plugins

document.addEventListener('DOMContentLoaded', function() {

    // add/remove helper classes
    if ('ontouchstart' in document) {
        document.documentElement.classList.remove('no-touch');
    }
    document.documentElement.classList.remove('is-preload');

    // navbar
    navbarToggleInit('#navToggler');

    $('[data-toggle="tooltip"]').tooltip();

});



// SIDE NAVIGATION

function navbarToggleInit(el, navClass) {
    document.querySelector(el).addEventListener('click', function() {
        document.querySelector('.c-navbar').classList.toggle('c-navbar--open');
    }, false);
}



// toggle blocks
$(document).on('click', '[data-toggle="toggle"]', function(e) {

    e.preventDefault();

    var link = this,
        target = link.parentNode,
        id = link.dataset.target || link.hash;

    if (id && id !== '') {
        target = document.querySelector(id);
    }

    if (target.classList.contains('is-open')) {
        //link.classList.remove('is-open');
        target.classList.remove('is-open');
    } else {
        //link.classList.add('is-open');
        target.classList.add('is-open');
    }

});



// prevent dropdown from closig when clicking inside it
$(document).on('click', '.dropdown-menu', function(e) {
    this.classList.contains('keep-open') && e.stopPropagation();
});